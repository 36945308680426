<template>
  <div>
    <b-card
      class="p-1"
      no-body
    >
      <b-card-header>
        <div>
          <b-card-title class="text-gray-800">
            Peso (preferencialmente em jejum)
            <span
              v-if="patient.weight_warning"
              class="badge ml-1"
            >!</span>
          </b-card-title>
        </div>
      </b-card-header>

      <b-card-body>
        <e-charts
          ref="line"
          :height="250"
          autoresize
          :options="chartjsDataCareWeight.option"
        />
      </b-card-body>
      <div
        v-if="itemsAlertFilter.length !== 0"
        class="mt-3"
      >
        <b-table
          :fields="fields"
          :items="itemsAlertFilter"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import 'twix'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import theme from '@core/components/charts/echart/theme.json'

import { option as chartjsDataCareWeight } from './chartjsDataCareWeight'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ECharts,
  },

  data() {
    return {
      patient: this.$store.getters['patient/patient'],
      showChart: false,
      chartjsDataCareWeight,
      selected: '',

      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
      fields: [
        {
          key: 'idealWeight',
          label: 'Peso configurado:',
        },
        {
          key: 'weight',
          label: 'Peso registrado:',
        },
        {
          key: 'weight_threshold',
          label: 'Notificação a partir do aumento em:',
        },
        {
          key: 'data',
          label: 'Data:',
        },
        {
          key: 'time',
          label: 'Horário:',
        },
      ],
      itemsAlert: [],
      itemsAlertFilter: [],
    }
  },

  computed: {
    careDate() {
      return `${this.$store.getters['patient/careDateStart']} até ${this.$store.getters['patient/careDateEnd']}`
    },
  },

  watch: {
    careDate() {
      this.changeData()
    },
    // eslint-disable-next-line func-names
    '$store.state.patient.selectedWeight': async function () {
      this.selected = await this.$store.getters['patient/selectedWeight']
      this.loadListAlert()
    },
  },

  mounted() {
    this.$store.commit('patient/UPDATE_SELECTED_WEIGHT', '')
    this.changeData()
  },

  destroyed() {
    this.alterAlert()
  },

  methods: {
    changeData() {
      this.showChart = false

      this.$store.dispatch('patient/dataChartsCareHeartFailure', { monitor_type: 'WEIGHT' })
        .then(response => {
          this.loadChart(response)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadChart(data) {
      const healthMonitorInfo = data.data.data.member_health_monitors_info
      const range = []

      const itr = moment.twix(moment(this.$store.getters['patient/careDateStart'], 'DD/MM/YYYY'),
        moment(this.$store.getters['patient/careDateEnd'], 'DD/MM/YYYY')).iterate('days')
      while (itr.hasNext()) {
        range.push(itr.next().format('DD/MM/YYYY'))
      }

      chartjsDataCareWeight.option.xAxis.data = range

      const arrayData = []
      const arrayDataAlert = []
      const arrayAlert = []
      let arrayAdd = true
      let arrayAddAlert = true

      range.forEach(valueRange => {
        arrayAdd = true
        arrayAddAlert = true
        healthMonitorInfo.forEach(valueHealthMonitor => {
          const dateHealthMonitorInfo = moment(valueHealthMonitor.occurrence_date).format('DD/MM/YYYY')
          if (valueRange === dateHealthMonitorInfo) {
            arrayData.push(valueHealthMonitor.weight)
            if (valueHealthMonitor.exceeded_weight_threshold === true) {
              arrayDataAlert.push(valueHealthMonitor.weight)
              const listAlert = {
                idealWeight: `${valueHealthMonitor.member_health_notification_info.ideal_weight} Kg`,
                weight: `${valueHealthMonitor.weight} Kg`,
                weight_threshold: `${valueHealthMonitor.member_health_notification_info.weight_threshold} Kg`,
                data: moment(valueHealthMonitor.occurrence_date).format('DD/MM/YYYY'),
                time: moment(valueHealthMonitor.occurrence_time, 'HH:mm:ss').format('HH:mm'),
              }
              arrayAlert.unshift(listAlert)
              arrayAddAlert = false
            }
            arrayAdd = false
          }
        })
        if (arrayAdd) {
          arrayData.push(null)
        }
        if (arrayAddAlert) {
          arrayDataAlert.push(null)
        }
      })

      this.itemsAlert = arrayAlert

      const newModelSeries = [
        {
          name: 'Peso dentro do limite configurado',
          data: arrayData,
          smooth: true,
          type: 'line',
          connectNulls: true,
          symbol: 'circle',
          symbolSize: 12,
        },
        {
          name: 'Peso acima do limite configurado',
          data: arrayDataAlert,
          smooth: true,
          type: 'line',
          connectNulls: false,
          symbol: 'circle',
          symbolSize: 12,
          lineStyle: {
            width: 0,
          },
        },
      ]

      chartjsDataCareWeight.option.series = newModelSeries

      this.showChart = true
    },
    async loadListAlert() {
      if (this.selected !== '') {
        const result = await this.itemsAlert.filter(item => item.data === this.selected)
        this.itemsAlertFilter = result
      }
    },
    alterAlert() {
      this.$store.dispatch('patient/readNotification')
        .then()
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .echarts {
    width: 100%;
  }
  .badge {
    display: inline-block;
    padding: 3px 9px; /* pixel unit */
    border-radius: 50%;
    font-size: 15px;
    text-align: center;
    background: #F8C52F;
    color: #000000;
  }
</style>
