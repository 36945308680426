<template>
  <div>
    <b-card>
      <div class="ml-2">
        <h3>
          Insuficiência cardíaca
        </h3>
      </div>
    </b-card>
    <b-card>
      <div class="ml-2">
        <template v-if="action === 'add'">
          <h5 class="mt-2">
            Adicionar uma notificação de aumento de peso no aplicativo de seu paciente.
          </h5>
        </template>
        <template v-else>
          <h5 class="mt-2">
            Editar uma notificação de aumento de peso no aplicativo de seu paciente.
          </h5>
        </template>
        <validation-observer ref="bariatricRules">
          <b-form>
            <div>
              <b-col class="mt-3">
                <b-form-group
                  label="Qual é o peso base do seu paciente?"
                  label-for="currentWeight"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="weight"
                    rules="requiredWeight"
                  >
                    <b-input-group class="input-group-merge  col-3 p-0">
                      <cleave
                        id="weight"
                        v-model="weight"
                        class="form-control"
                        :raw="false"
                        :options="options.weight"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Ex: 120"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          Kg
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="mt-2">
                <b-form-group
                  label="Após aumentar quantos quilos em relação ao peso base você quer receber uma notificação?"
                  label-for="currentWeight"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="weightThreshold"
                    rules="requiredWeightThreshold"
                  >
                    <b-input-group class="input-group-merge col-3 p-0">
                      <cleave
                        id="weightThreshold"
                        v-model="weightThreshold"
                        class="form-control"
                        :raw="false"
                        :options="options.weightThreshold"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Ex: 1"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          Kg
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
        <b-row>
          <b-col
            class="mt-3"
            cols="6"
          >
            <b-button
              type="button"
              variant="link"
              title="Cancelar"
              @click="cancel"
            >
              Cancelar
            </b-button>
            <b-button
              type="button"
              variant="outline-primary"
              class="ml-2"
              :disabled="buttonDisable"
              title="Salvar"
              @click="save"
            >
              <feather-icon
                size="16"
                icon="SendIcon"
                class="mr-50"
              />
              Cadastrar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BCard, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupText,
  BForm, BRow, BButton,
} from 'bootstrap-vue'
import { required } from 'vee-validate/dist/rules'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BCard,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BForm,
    BRow,
    BButton,

    Cleave,

    ValidationProvider,
    ValidationObserver,

  },

  data() {
    return {
      action: '',
      weight: '',
      weightThreshold: '',
      buttonDisable: false,
      options: {
        weight: {
          numeral: true,
          numeralDecimalMark: ',',
          numeralIntegerScale: 3,
          numeralDecimalScale: 1,
        },
        weightThreshold: {
          numeral: true,
          numeralDecimalMark: ',',
          numeralIntegerScale: 3,
          numeralDecimalScale: 1,
        },
      },
    }
  },

  created() {
    extend('requiredWeight', {
      ...required,
      validate: () => {
        if (this.weight === '') {
          if (this.$refs.weight.flags.touched === true || this.$refs.weight.flags.changed === false) {
            return 'Campo obrigatório'
          }
        }
        return true
      },
    })
    extend('requiredWeightThreshold', {
      ...required,
      validate: () => {
        if (this.weightThreshold === '') {
          if (this.$refs.weightThreshold.flags.touched === true || this.$refs.weightThreshold.flags.changed === false) {
            return 'Campo obrigatório'
          }
        }
        return true
      },
    })

    this.loadData()
  },

  mounted() {

  },

  methods: {
    async loadData() {
      const notificationValue = await this.$store.getters['patient/valueNotification']
      this.action = await notificationValue.action
      if (this.action === 'edit') {
        this.weight = await notificationValue.idealWeight.toString().replace('.', ',')
        this.weightThreshold = await notificationValue.weightThreshold.toString().replace('.', ',')
      }
    },
    async save() {
      this.$refs.bariatricRules.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const dataForm = {
            weight: this.weight.replace(',', '.'),
            weightThreshold: this.weightThreshold.replace(',', '.'),
          }
          this.$store.dispatch('patient/saveWeightNotification', dataForm)
            .then(() => {
              this.$swal({
                title: 'Notificação de peso configurado com sucesso!',
                text: 'Agora, você e seu seu paciente serão notificados todas as vezes que o peso informado ultrapassar o recomendado.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.isConfirmed) {
                    this.buttonDisable = false
                    this.$store.commit('patient/UPDATE_SHOW_NOTIFICATION', false)
                  }
                })
            },
            () => {
              this.$swal({
                title: 'Erro!',
                text: 'Erro ao salvar',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.buttonDisable = false
            })
        }
      })
    },
    cancel() {
      this.$store.commit('patient/UPDATE_SHOW_NOTIFICATION', false)
    },
  },
}

</script>
