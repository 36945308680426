<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="8"
        cols="12"
      >
        <b-card
          class="p-1"
        >
          <b-row>
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="lg"
                    class="border mr-1"
                    :src="patient.avatar_url"
                    :text="avatarText(patient.name)"
                    variant="primary"
                    :badge-variant="patient.weight_warning ? 'yellow' : ''"
                    :badge="patient.weight_warning ? '!' : ''"
                    badge-offset="-0.5em"
                  />
                </template>
                <div class="font-weight-bold d-block text-nowrap">
                  <strong>{{ patient.name }}</strong>
                </div>
                <template v-if="patient.birthdate">
                  {{ formatDateBR(patient.birthdate) }} - {{ patient.age }} Anos
                </template>
              </b-media>
            </b-col>

            <b-col>
              <div class="font-weight-bold d-block text-nowrap">
                <strong>
                  Data de ingresso
                </strong>
              </div>
              {{ formatDateBR(patient.created_at) }}
            </b-col>

          </b-row>
          <div class="mt-2">
            <h5 class="mb-0">
              Doenças relatadas
            </h5>
          </div>
          {{ formatString(patient.member_cares_name) }}
        </b-card>
      </b-col>

      <b-col>
        <b-card class="p-1">
          <h6 class="font-weight-normal">
            Adesão média dos últimos 30 dias
          </h6>
          <span class="h1 font-weight-bolder d-block text-gray-800 mb-2 mt-2">
            {{ patient.adherence }}%
          </span>
          <small class="d-block">
            Total
          </small>
        </b-card>
      </b-col>
      <b-col
        cols="12"
      >
        <patient-tab v-show="showTab" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BCard, BCol, BRow, BAvatar, BMedia,
} from 'bootstrap-vue'
import avatarText from '@/utils/filter'
import patientTab from './Patient-tab.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BAvatar,
    BMedia,

    patientTab,
  },

  data() {
    return {
      patient: {},
      showTab: true,
      avatarText,
    }
  },

  mounted() {
    this.patient = this.$store.getters['patient/patient']
    this.showTab = this.$store.getters['patient/showTab']
  },

  methods: {
    formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    },
    formatString(value) {
      if (value !== undefined) {
        const lengthArray = value.length
        let stringWeek = ''

        if (lengthArray > 0) {
          value.forEach((valueArray, index) => {
            stringWeek += valueArray
            if (index < lengthArray - 2) {
              stringWeek += ', '
            } else if (index === lengthArray - 2) {
              stringWeek += ' e '
            }
          })
        } else {
          stringWeek = '-'
        }

        return stringWeek
      }
      return ''
    },
  },
}
</script>
