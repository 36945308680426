<template>
  <div>
    <b-card
      v-if="optionJourney.length > 0 && !editNotification"
      class="p-1"
    >
      <b-row>
        <b-col
          cols="6"
        >
          <template v-if="optionJourney.length > 1">
            <label
              class="h5"
              for="selectedJourney"
            >Selecione uma linha de cuidado:</label>
            <v-select
              id="selectedJourney"
              v-model="selectedJourney"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionJourney"
              :value="optionJourney"
              placeholder="Selecione"
              :reduce="optionJourney => optionJourney.identifier"
              label="name"
            />
          </template>
          <template v-else>
            <h3
              class="mt-2 ml-2"
            >
              <template v-if="optionJourney.length">
                {{ optionJourney[0].name }}
              </template>
            </h3>
          </template>
        </b-col>
        <b-col>
          <div v-if="!editNotification">
            <b-form-group class="mb-0">
              <label
                class="h5"
                for="date"
              >Data inicial/final:</label>
              <flat-pickr
                id="date"
                v-model="careDate"
                class="form-control"
                :config="configDate"
                @on-change="onCareDateChange"
                @on-close="onCareDateClose"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <patient-tab-care-epilepsy-chart v-if="selectedJourney === 'epilepsy'" />
    <patient-tab-care-heart-failure-info v-if="selectedJourney === 'heart_failure' && !editNotification" />
    <patient-tab-care-heart-failure-weight-chart v-if="selectedJourney === 'heart_failure' && !editNotification" />
    <patient-tab-care-heart-failure-pressure-chart v-if="selectedJourney === 'heart_failure' && !editNotification" />
    <patient-tab-care-heart-failure-pulse-chart v-if="selectedJourney === 'heart_failure' && !editNotification" />
    <patient-tab-care-notification-weight v-if="selectedJourney === 'heart_failure' && editNotification" />
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BFormGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import 'twix'

import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import PatientTabCareEpilepsyChart from './epilepsy/Patient-tab-care-chart.vue'
import PatientTabCareHeartFailureInfo from './heart-failure/Patient-tab-core-info.vue'
import PatientTabCareHeartFailureWeightChart from './heart-failure/Patient-tab-care-weight-chart.vue'
import PatientTabCareHeartFailurePressureChart from './heart-failure/Patient-tab-care-pressure-chart.vue'
import PatientTabCareHeartFailurePulseChart from './heart-failure/Patient-tab-care-pulse-chart.vue'
import PatientTabCareNotificationWeight from './Patient-tab-notification-weight.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,

    PatientTabCareEpilepsyChart,
    PatientTabCareHeartFailureInfo,
    PatientTabCareHeartFailureWeightChart,
    PatientTabCareHeartFailurePressureChart,
    PatientTabCareHeartFailurePulseChart,
    PatientTabCareNotificationWeight,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    flatPickr,
  },

  data() {
    return {
      careDate: `${this.$store.getters['patient/careDateStart']} até ${this.$store.getters['patient/careDateEnd']}`,
      optionJourney: [],
      selectedJourney: '',

      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
    }
  },

  computed: {
    ...mapGetters({
      editNotification: 'patient/showNotification',
    }),
  },

  beforeMount() {
    this.optionsJourney()
  },

  methods: {
    optionsJourney() {
      this.$store.dispatch('patient/listJoyrneys')
        .then(response => {
          const optionJourney = response.data.data.journeys_info
          const filteredJourney = optionJourney.filter(item => item.identifier !== 'obesity_bariatric')
          this.optionJourney = filteredJourney
          if (filteredJourney.length === 1) {
            this.selectedJourney = filteredJourney[0].identifier
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onCareDateChange(selectedDates, dataString, instance) {
      if (selectedDates.length === 1) {
        /* eslint-disable no-param-reassign */
        instance.config.minDate = moment(selectedDates[0]).format('DD/MM/YYYY')
        instance.config.maxDate = moment(selectedDates[0]).add(30, 'days') >= moment() ? moment().format('DD/MM/YYYY') : moment(selectedDates[0]).add(30, 'days').format('DD/MM/YYYY')
      } else if (selectedDates.length === 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = moment().format('DD/MM/YYYY')

        const careDateStart = moment(selectedDates[0]).format('DD/MM/YYYY')
        const careDateEnd = moment(selectedDates[1]).format('DD/MM/YYYY')

        this.$store.commit('patient/UPDATE_CARE_DATE_START', careDateStart)
        this.$store.commit('patient/UPDATE_CARE_DATE_END', careDateEnd)
      }
    },
    onCareDateClose(selectedDates, dataString, instance) {
      if (selectedDates.length === 1) {
        instance.clear()
        /* eslint-disable no-param-reassign */
        instance.config.minDate = null
        instance.config.maxDate = moment().format('DD/MM/YYYY')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
