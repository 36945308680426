<template>
  <div class="m-1">
    <b-row>
      <b-col
        cols="5"
      >
        <b-form-group
          label="Data do lembrete"
          label-for="date"
        >

          <validation-provider
            #default="{ errors }"
            name="data do lembrete"
            rules="required"
          >

            <b-form-datepicker
              v-model="date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt"
              placeholder=""
              :min="today()"
              @input="save()"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,

    ValidationProvider,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      date: '',
    }
  },

  mounted() {
    this.date = this.$store.getters['formOther/frequencyDate']
  },

  methods: {
    today() {
      return moment().format('YYYY-MM-DD')
    },
    save() {
      this.$store.commit('formOther/UPDATE_FREQUENCY_DATE', this.date)
    },
  },

}
</script>
