<template>
  <div>

    <b-tabs class="patient-tabs">
      <b-tab
        title="Adesão"
        :active="activeTab === 'Adesão'"
        @click="clear(), show='Adesão'"
      >
        <b-card class="p-1">
          <patient-tab-adherence />
        </b-card>
      </b-tab>
      <b-tab
        :active="activeTab === 'Linha de cuidado'"
        @click="clear(), show='Linha de cuidado'"
      >
        <template #title>
          Linha de cuidado
          <span
            v-if="patient.weight_warning"
            class="badgeTab ml-1"
          >!</span>
        </template>
        <b-card class="p-1">
          <patient-tab-care />
        </b-card>
      </b-tab>
      <b-tab
        title="Lembrete de medicamento"
        :active="activeTab === 'Lembrete de medicamento'"
        @click="clear(), show='Lembrete de medicamento'"
      >
        <b-card class="p-1">
          <patient-tab-reminder />
        </b-card>
      </b-tab>
      <b-tab
        title="Outros lembretes"
        :active="activeTab === 'Outros lembretes'"
        @click="clear(), show='Outros lembretes'"
      >
        <b-card class="p-1">
          <patient-tab-other-reminder />
        </b-card>
      </b-tab>
      <b-tab
        title="Enviar conteúdo"
        :active="activeTab === 'Enviar conteúdo'"
        @click="clear(), show='Enviar conteúdo'"
      >
        <b-card class="p-1">
          <patient-tab-content />
        </b-card>
      </b-tab>
    </b-tabs>

    <patient-tab-adherence-chart v-if="show === 'Adesão'" />
    <patient-tab-care-filter v-if="show === 'Linha de cuidado'" />
    <patient-tab-reminder-table v-if="showReminderTable && show === 'Lembrete de medicamento'" />
    <patient-tab-reminder-card v-if="showReminderAdd && show === 'Lembrete de medicamento'" />
    <patient-tab-reminder-favorite v-if="showReminderFavorite && show === 'Lembrete de medicamento'" />
    <patient-tab-other-reminder-table v-if="showOtherReminderTable && show === 'Outros lembretes'" />
    <patient-tab-content-table v-if="showContentTable && show === 'Enviar conteúdo'" />
    <patient-tab-content-add v-if="showContentAdd && show === 'Enviar conteúdo'" />

  </div>
</template>

<script>
import {
  BTab, BTabs, BCard,
} from 'bootstrap-vue'

import PatientTabAdherence from './Patient-tab-adherence.vue'
import PatientTabAdherenceChart from './Patient-tab-adherence-chart.vue'
import PatientTabCare from './Patient-tab-care.vue'
import PatientTabCareFilter from './Patient-tab-care-filter.vue'
import PatientTabReminder from './Patient-tab-reminder.vue'
import PatientTabReminderTable from './Patient-tab-reminder-table.vue'
import PatientTabReminderCard from './Patient-tab-reminder-card.vue'
import PatientTabReminderFavorite from './Patient-tab-reminder-favorite.vue'
import PatientTabOtherReminder from './Patient-tab-other-reminder.vue'
import PatientTabOtherReminderTable from './Patient-tab-other-reminder-table.vue'
import PatientTabContent from './Patient-tab-content.vue'
import PatientTabContentTable from './Patient-tab-content-table.vue'
import PatientTabContentAdd from './Patient-tab-content-add.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,

    PatientTabAdherence,
    PatientTabAdherenceChart,
    PatientTabCare,
    PatientTabCareFilter,
    PatientTabReminder,
    PatientTabReminderTable,
    PatientTabReminderCard,
    PatientTabReminderFavorite,
    PatientTabOtherReminder,
    PatientTabOtherReminderTable,
    PatientTabContent,
    PatientTabContentTable,
    PatientTabContentAdd,
  },

  data() {
    return {
      show: this.$store.getters['patient/showActive'],
      showReminderTable: '',
      showReminderAdd: '',
      showReminderFavorite: '',
      showOtherReminderTable: '',
      showContentTable: '',
      showContentAdd: '',
      activeTab: '',
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.patient.showReminderTable': function () {
      this.showReminderTable = this.$store.state.patient.showReminderTable
    },
    // eslint-disable-next-line func-names
    '$store.state.patient.showReminderAdd': function () {
      this.showReminderAdd = this.$store.state.patient.showReminderAdd
    },
    // eslint-disable-next-line func-names
    '$store.state.patient.showReminderFavorite': function () {
      this.showReminderFavorite = this.$store.state.patient.showReminderFavorite
    },
    // eslint-disable-next-line func-names
    '$store.state.patient.showOtherReminderTable': function () {
      this.showOtherReminderTable = this.$store.state.patient.showOtherReminderTable
    },
    // eslint-disable-next-line func-names
    '$store.state.patient.showContentTable': function () {
      this.showContentTable = this.$store.state.patient.showContentTable
    },
    // eslint-disable-next-line func-names
    '$store.state.patient.showContentAdd': function () {
      this.showContentAdd = this.$store.state.patient.showContentAdd
    },

  },

  mounted() {
    this.clear()
    this.activeTab = this.$store.getters['patient/showActive']
    this.showReminderTable = this.$store.getters['patient/showReminderTable']
    this.showReminderAdd = this.$store.getters['patient/showReminderAdd']
    this.showReminderFavorite = this.$store.getters['patient/showReminderFavorite']
    this.showOtherReminderTable = this.$store.getters['patient/showOtherReminderTable']
    this.showContentTable = this.$store.getters['patient/showContentTable']
    this.showContentAdd = this.$store.getters['patient/showContentAdd']
    this.patient = this.$store.getters['patient/patient']
  },

  methods: {
    clear() {
      this.$store.commit('patient/UPDATE_SHOW_REMINDER_TABLE', true)
      this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
      this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', false)
      this.$store.commit('patient/UPDATE_SHOW_OTHER_REMINDER_TABLE', true)
      this.$store.commit('patient/UPDATE_SHOW_CONTENT_TABLE', true)
      this.$store.commit('patient/UPDATE_SHOW_CONTENT_ADD', false)
    },
  },

}
</script>

<style lang="scss">
  .patient-tabs {
    .nav {
      margin-bottom: 0;
      justify-content: space-around;

      .nav-link {
          padding: 0.71rem 1.5rem;
          font-size: 1.1rem;
          color: #3279eb;
          transition: 0.2s ease-in all;

          &:hover{
            color: #0058F9;
          }

          &.active::after {
            background-color: #0058F9 !important;
          }

          &.active {
            color: #0058F9;
            background: #FFFFFF;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }
      }
    }
  }
  .badgeTab {
    display: inline-block;
    padding: 1px 9px; /* pixel unit */
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    background: #F8C52F;
    color: #000000;
    margin-top: -1rem;
}
</style>
