<template>
  <div>
    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        {{ this.$store.getters['formMedication/action'] }}
      </h4>
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>
      <validation-observer ref="timeRules">
        <b-form>
          <b-row>

            <b-col
              cols="6"
            >
              <b-form-group
                class="mt-2"
              >
                Frequência

                <b-form-radio
                  v-model="selectedFrequency"
                  class="mt-1"
                  value="Todos os dias"
                >
                  Todos os dias
                </b-form-radio>

                <b-form-radio
                  v-model="selectedFrequency"
                  class="mt-1"
                  value="Dias específicos da semana"
                >
                  Dias específicos da semana
                </b-form-radio>

                <form-other-frequency-week v-if="selectedFrequency === 'Dias específicos da semana'" />

                <b-form-radio
                  v-model="selectedFrequency"
                  class="mt-1"
                  value="Um dia específico"
                >
                  Um dia específico
                </b-form-radio>

                <form-other-frequency-date v-if="selectedFrequency === 'Um dia específico'" />

              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="button"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="outline-primary"
          class="ml-2"
          @click="previous"
        >
          Voltar
        </b-button>
        <b-button
          type="button"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Continuar
        </b-button>
      </b-col>
    </b-card>
    <form-other-abstract />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BBreadcrumb, BForm, BRow, BCol, BFormGroup, BButton, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formOtherFrequencyWeek from './Form-other-frequency-week.vue'
import formOtherFrequencyDate from './Form-other-frequency-date.vue'
import formOtherAbstract from './Form-other-abstract.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormRadio,

    formOtherFrequencyWeek,
    formOtherFrequencyDate,
    formOtherAbstract,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      selectedFrequency: [],
      items: [
        {
          text: 'Título',
          active: true,
        },
        {
          text: 'Horário',
          active: true,
        },
        {
          text: 'Frequência',
          active: false,
        },
        {
          text: 'Resumo',
          active: true,
        },
      ],
    }
  },

  mounted() {
    this.selectedFrequency = this.$store.getters['formOther/frequency']
    this.link = this.$store.getters['formOther/link']
  },

  methods: {
    next() {
      this.$refs.timeRules.validate().then(success => {
        if (success) {
          this.$store.commit('formOther/UPDATE_FREQUENCY', this.selectedFrequency)
          this.$store.commit('formOther/UPDATE_SHOW_FREQUENCY', true)
          this.$router.push(`${this.link}-finish`)
        }
      })
    },
    previous() {
      this.$router.push(`${this.link}-time`)
    },
    cancel() {
      this.$store.commit('patient/UPDATE_SHOW_TAB', true)
      this.$router.push('patient-edit')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
