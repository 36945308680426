import { render, staticRenderFns } from "./Patient-tab-other-reminder-table.vue?vue&type=template&id=54e16bf5&scoped=true&"
import script from "./Patient-tab-other-reminder-table.vue?vue&type=script&lang=js&"
export * from "./Patient-tab-other-reminder-table.vue?vue&type=script&lang=js&"
import style0 from "./Patient-tab-other-reminder-table.vue?vue&type=style&index=0&id=54e16bf5&lang=scss&scoped=true&"
import style1 from "./Patient-tab-other-reminder-table.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./Patient-tab-other-reminder-table.vue?vue&type=style&index=2&id=54e16bf5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e16bf5",
  null
  
)

export default component.exports