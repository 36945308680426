<template>
  <div
    class="m-2"
  >
    <h4 class="mt-1">
      Adicionar um novo lembrete de medicamento
    </h4>

    <b-row class="m-2">
      <div
        class="rectangle mt-2"
      >
        <div
          @click="favorite"
        >
          <b-img
            src="@/assets/images/icons/medicamento-favorito.jpg"
            width="100"
            class="mt-1"
          />
          <p
            class="m-2"
          >
            Usar um medicamento favorito
          </p>
        </div>
      </div>
      <div
        class="rectangle ml-3 mt-2"
      >
        <div
          @click="newReminder"
        >
          <b-img
            src="@/assets/images/icons/medicamento-favorito.jpg"
            width="100"
            class="mt-1"
          />
          <p
            class="m-2"
          >
            Criar um novo lembrete de medicamento
          </p>
        </div>
      </div>
    </b-row>
  </div>

</template>

<script>
import { BImg, BRow } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BRow,
  },

  mounted() {
    this.$store.commit('formMedication/RESET')
    this.$store.commit('formMedication/UPDATE_LINK', 'patient')
    this.$store.commit('formMedication/UPDATE_ACTION', 'Adicionar um novo lembrete de medicamento')
  },

  methods: {
    newReminder() {
      this.$store.commit('patient/UPDATE_SHOW_TAB', false)

      this.$store.commit('formMedication/UPDATE_TIME', 'Intervalos fixos (8 em 8h, 6 em 6h…)')
      this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias contínuos (sem pausas)')
      this.$store.commit('formMedication/UPDATE_DURATION', 'Uso contínuo (sem data de término)')

      this.$router.push('patient-medication')
    },
    favorite() {
      this.$store.commit('patient/UPDATE_SHOW_TAB', false)
      this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
      this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
    },
  },
}

</script>

<style scoped>
  .rectangle {
    text-align: center;
    width: 175px;
    height: 250px;
    box-shadow: 0px 0px 1em rgb(179, 176, 176);
  }
</style>
