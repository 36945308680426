<template>
  <div class="mb-2">
    <b-card-group deck>
      <b-card class="p-1">
        <h5 class="text-gray-800">
          Notificação de aumento de peso em jejum acima do recomendado
        </h5>
        <div v-if="!messageError">
          <b-row class="mt-2">
            <b-col class="col-3">
              Peso ideal:
              <br>
              <h3>
                <strong>
                  {{ idealWeight }} Kg
                </strong>
              </h3>
            </b-col>
            <b-col class="col">
              Avisar a partir do aumento em:
              <br>
              <h3>
                <strong>
                  {{ weightThreshold }} Kg
                </strong>
              </h3>
            </b-col>
            <b-col class="col-3 pl-0 pr-0">
              <b-button
                type="button"
                variant="outline-danger"
                class="w-100"
                @click="showRemove=true"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="16"
                  class="align-text-top"
                />
                Excluir
              </b-button>
              <br>
              <b-button
                type="button"
                variant="outline-primary"
                class="mt-1 w-100"
                @click="edit"
              >
                <feather-icon
                  icon="BellIcon"
                  size="16"
                  class="align-text-top"
                />
                Editar
              </b-button>
            </b-col>
          </b-row>
          Criado por {{ createdBy }} em {{ dateCreated }}
        </div>
        <div
          v-else
          class="mt-2 mb-md-0 mb-sm-2"
        >
          <b-row>
            <b-col class="col-7">
              Este paciente ainda não configurou uma notificação para avisar quando ultrapassar o peso recomendado. Configure um aviso e monitore seu paciente.
            </b-col>

            <b-col
              class="text-right"
              align-self="end"
            >
              <b-button
                variant="outline-primary"
                class="mt-auto"
                @click="add()"
              >
                <feather-icon
                  icon="BellIcon"
                  size="15"
                  class="align-text-middle"
                />
                Criar notificação
              </b-button>
            </b-col>
          </b-row>

        </div>
      </b-card>
      <b-card class="p-1">
        <div>
          <h5 class="text-gray-800">
            LME Fácil
          </h5>

          <b-row>
            <b-col>
              <p class="text-gray-800 mb-md-0 mb-sm-2">
                Aqui você encontra uma plataforma gratuita que auxilia no preenchimento do Laudo de Medicamento Especializado (LME), na elaboração da prescrição e deixa todos os documentos salvos na nuvem para uma rápida renovação.
              </p>
            </b-col>
            <b-col
              md="auto"
              class="align-self-end"
            >
              <b-button
                variant="outline-primary"
                class="float-right"
                href="https://lmefacil.com.br/#/sessao/login"
                target="_blank"
                title="Acessar LME Fácil"
              >
                <feather-icon
                  icon="ClipboardIcon"
                  size="15"
                  class="align-text-middle"
                />
                Acessar LME Fácil
              </b-button>
            </b-col>
          </b-row>

        </div>
      </b-card>
    </b-card-group>
    <b-modal
      v-model="showRemove"
      centered
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      ok-title="Excluir"
      hide-header
      footer-class="button-footer"
      @ok="remove"
    >
      <b-card-text class="text-center">
        <feather-icon
          icon="AlertCircleIcon"
          stroke="red"
          size="60"
        />
        <div class="mt-2">
          <strong>Atenção!</strong>
        </div>
        <div class="mt-2">
          Deseja excluir esta notificação de peso?
          <br>
          Não será possível desfazer esta ação.
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardGroup, BButton, BModal, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BButton,
    BModal,
    BCardText,
  },

  data() {
    return {
      showRemove: false,
      messageError: false,
      notificationId: '',
      idealWeight: '',
      weightThreshold: '',
      createdBy: '',
      dateCreated: '',
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.$store.dispatch('patient/listNotifications')
        .then(response => {
          const notificationInfo = response.data.data.member_health_notification_info
          if (notificationInfo.id === undefined) {
            this.messageError = true
          } else {
            this.notificationId = notificationInfo.id
            this.idealWeight = notificationInfo.ideal_weight.toString().replace('.', ',')
            this.weightThreshold = notificationInfo.weight_threshold.toString().replace('.', ',')

            const userArray = notificationInfo.created_by.split(' ')
            const user = `${userArray[0]} ${userArray[userArray.length - 1]}`
            this.createdBy = user

            this.dateCreated = moment(notificationInfo.created_at).format('DD/MM/YYYY')
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async add() {
      const valueNotification = {
        action: 'add',
      }
      await this.$store.commit('patient/UPDATE_NOTIFICATION', valueNotification)
      await this.$store.commit('patient/UPDATE_SHOW_NOTIFICATION', true)
    },
    async edit() {
      const valueNotification = {
        action: 'edit',
        idealWeight: this.idealWeight,
        weightThreshold: this.weightThreshold,
      }
      await this.$store.commit('patient/UPDATE_NOTIFICATION', valueNotification)
      await this.$store.commit('patient/UPDATE_SHOW_NOTIFICATION', true)
    },
    remove() {
      this.$store.dispatch('patient/removeNotification', this.notificationId)
        .then(() => {
          this.loadData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}

</script>

<style>
  .button-footer {
    justify-content: center;
  }
</style>
