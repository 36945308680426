<template>
  <div>
    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        Todos os meus arquivos
      </h4>

      <b-row
        class="justify-content-md-center"
        align-v="center"
      >
        <template v-if="link === 'library'">
          <b-col
            md="3"
            class="mt-2"
          >
            <b-button
              variant="primary"
              @click="addFile"
            >
              Adicionar novo conteúdo
            </b-button>
          </b-col>
          <b-col
            class="p-0"
          >
            <template v-if="countSelected <= 1">
              <b-button
                v-if="countSelected > 0"
                variant="outline-primary"
                class="ml-1 mt-2"
                @click="editFile"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-50"
                />
                Editar
              </b-button>
              <b-button
                v-if="countSelected > 0"
                variant="outline-primary"
                class="ml-1 mt-2"
                @click="downloadFile"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                Visualizar
              </b-button>
            </template>
            <b-button
              v-if="countSelected > 0"
              variant="outline-danger"
              class="ml-1 mt-2"
              @click="show=true"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              Excluir
            </b-button>
          </b-col>
        </template>
        <template v-else>
          <b-col
            md="8"
            class="mt-2"
          >
            <b-button
              v-if="countSelected > 0"
              variant="primary"
              class="ml-1"
              @click="send()"
            >
              Enviar
            </b-button>
          </b-col>
        </template>
        <b-col
          md="4"
          align-self="start"
        >
          <div
            v-if="files.length > 0"
          >
            Ordernar por:
            <v-select
              v-model="order"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionOrder"
              :value="optionOrder.id"
              label="name"
              @search:blur="orderImage(order.id)"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols
        />
        <b-col
          md="4"
          class="mt-2 text-right"
        >
          <div v-if="countSelected > 0">
            <b-button
              style="color:#6e6b7b"
              variant="link"
              @click="clearSelected"
            >
              <span STYLE="font-size:125%">X</span>&nbsp;&nbsp;&nbsp;{{ countSelected }} Selecionado(s)
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="renderComponent"
        class="mt-2"
      >
        <div
          v-if="files.length === 0"
          class="mt-2 text-center"
        >
          <strong>Sua biblioteca ainda está vazia.</strong>
          <br>
          Para enviar um novo arquivo, basta clicar em <br>"Adicionar novo conteúdo".
        </div>
        <div
          v-for="k, index in lineShow"
          :key="index"
          class="text-center"
        >
          <b-row
            class="mt-2 d-flex justify-content-between"
          >
            <div
              v-for="n in fileGroup[k-1]"
              :key="n.id"
            >
              <div class="card">
                <b-card
                  border-variant="light"
                  img-alt="Card image"
                  img-top
                  class="rectangle"
                >
                  <b-card-img
                    v-if="n.filename.substring(n.filename.length - 3) === 'pdf'"
                    class="card-img-top"
                    src="@/assets/images/pages/icone-arquivos-user.png"
                  />
                  <b-card-img
                    v-else
                    class="card-img-top"
                    src="@/assets/images/pages/icone-imagem-user.png"
                  />
                  <div
                    class="image"
                    @click="select({id: n.id, title: n.title, path: n.file_path})"
                  >
                    <span
                      v-if="idSelected[n.id]"
                      class="fontCheck"
                    >
                      &#10003;
                    </span>
                  </div>
                  <b-link
                    style="color:#6e6b7b"
                    :href="n.file_path"
                    target="_blank"
                  >
                    <b-card-text class="mt-2">
                      {{ n.title }}
                    </b-card-text>
                  </b-link>
                </b-card>
              </div>
            </div>
          </b-row>
        </div>

        <div class="mt-2 text-center">
          <b-link
            v-if="fileGroup.length > lineShow"
            @click="plus"
          >
            Ver mais
            <feather-icon
              icon="ChevronDownIcon"
              class="mr-50"
            />
          </b-link>
        </div>
      </div>
    </b-card>

    <b-modal
      v-model="show"
      centered
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      ok-title="Excluir"
      hide-header
      @ok="deleteFile"
    >
      <b-card-text class="text-center">
        <feather-icon
          icon="AlertCircleIcon"
          stroke="red"
          size="60"
        />
        <div class="mt-2">
          <strong>Atenção!</strong>
        </div>
        <div class="mt-2">
          Deseja excluir este(s) conteúdo(s) da sua biblioteca?
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BCardText, BLink, BButton, BCardImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCardText,
    BLink,
    BButton,
    BCardImg,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },

  data() {
    return {
      show: false,
      imageLast: 1,
      lineShow: 1,
      files: [],
      renderComponent: false,
      order: ['Mais recentes'],
      optionOrder: [{ id: 1, name: 'Mais recentes' }, { id: 2, name: 'Mais antigos' }, { id: 3, name: 'Título: A - Z' }, { id: 4, name: 'Título: Z - A' }],
      imagesSelected: [],
      countSelected: 0,
      idSelected: [],
      link: '',
    }
  },

  created() {
    this.listImages()
  },

  mounted() {
    this.link = this.$store.getters['library/link']
  },

  methods: {
    listImages() {
      this.$store.dispatch('library/listFile')
        .then(response => {
          this.files = response.data.data.worker_library_info
          this.orderImage(1)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    image(path) {
      if (path.substring(path.length - 3) === 'pdf') {
        return '@/assets/images/pages/icone-imagem-user.png'
      }
      return '@/assets/images/pages/icone-imagem-user.png'
    },

    divArray(array, countRegister) {
      const newArray = []
      let group = 0

      for (let i = 0; i < array.length; i += 1) {
        if (newArray[group] === undefined) {
          newArray[group] = []
        }

        newArray[group].push(array[i])

        if ((i + 1) % countRegister === 0) {
          group += 1
        }
      }
      return newArray
    },

    orderImage(type) {
      this.renderComponent = false
      const arrayTemp = this.files
      if (type === 1 || type === 2) {
        arrayTemp.sort((a, b) => {
          if (a.released_at > b.released_at) {
            if (type === 1) {
              return -1
            }
            return 1
          }
          if (a.released_at < b.released_at) {
            if (type === 1) {
              return 1
            }
            return -1
          }
          return 0
        })
      }

      if (type === 3 || type === 4) {
        arrayTemp.sort((a, b) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            if (type === 3) {
              return 1
            }
            return -1
          }
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            if (type === 3) {
              return -1
            }
            return 1
          }
          return 0
        })
      }

      this.fileGroup = this.divArray(arrayTemp, 5)
      this.renderComponent = true
    },
    plus() {
      this.lineShow += 1
    },
    addFile() {
      this.$router.push('/library-add')
    },
    editFile() {
      this.$store.commit('library/UPDATE_TITLE', this.imagesSelected[0])
      this.$router.push('/library-edit')
    },
    deleteFile() {
      this.$store.dispatch('library/removeFile', { data: this.imagesSelected })
        .then(() => {
          this.countSelected = 0
          this.imagesSelected = []
          this.idSelected = []
          this.listImages()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downloadFile() {
      const link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.download = this.imagesSelected[0].title
      link.href = this.imagesSelected[0].path
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    select(event) {
      if (!this.imagesSelected.filter(e => e.id === event.id).length > 0) {
        this.imagesSelected.push(event)
        this.idSelected[event.id] = true
      } else {
        const index = this.imagesSelected.map(e => e.id).indexOf(event.id)
        if (index !== -1) {
          this.imagesSelected.splice(index, 1)
          this.idSelected[event.id] = false
        }
      }
      this.countSelected = this.imagesSelected.length
    },
    send() {
      this.$store.dispatch('library/sendFile', { data: this.imagesSelected })
        .then(() => {
          this.$swal({
            title: 'Conteúdo enviado com sucesso!',
            text: 'Agora, seu paciente será notificado sobre o(s) novo(s) conteúdo(s).',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.isConfirmed) {
                this.$store.commit('patient/UPDATE_SHOW_CONTENT_TABLE', true)
                this.$store.commit('patient/UPDATE_SHOW_CONTENT_ADD', false)
              }
            })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    clearSelected() {
      const filtered = this.imagesSelected.filter(x => x !== undefined)
      filtered.forEach(val => {
        this.select(val)
      })
    },
  },
}
</script>

<style scoped>
  .card-body {
    padding: 0px;
  }
  .card-img-top {
    background-color: #3279EB4D;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 59.01px;
    padding-right: 59.01px;
    text-align: center;
    border-radius: 10px
  }
  .rectangle {
    text-align: center;
    max-width: 175px;
    height: 250px;
    margin-left: 1.2rem;
    margin-right: 1.2rem ;
  }
  .card {
    position: relative;
  }
  .image {
    position: absolute;
    top: 3%;
    left: 83%;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
  }
  .fontCheck {
    size: 16px;
    color: #0258FE;
  }

</style>

<style scoped>
  ::v-deep .modal-footer {
    justify-content: center;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
