<template>

  <div>
    <b-card
      no-body
      class="mb-0 mt-2"
    >

      <div class="m-2">
        <b-row>
          <b-col />
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                type="text"
                placeholder="Buscar medicamento favorito"
              />
            </b-input-group>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refFavoriteListTable"
        class="position-relative"
        :items="fetchFavorites"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Você ainda não possui medicamentos favoritos cadastrados."
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(medicamento)="data">
          {{ data.item.medication_info.name }}
        </template>

        <template
          #cell(horario)="data"
        >

          <div class="pre-formatted">{{
            time(data.item.template_schedule_info.schedule_times_type
                 ,data.item.template_schedule_info.schedule_times)
          }}
          </div>
        </template>

        <template #cell(frequência)="data">
          <template v-if="data.item.template_schedule_info.schedule_week_days.length > 0">
            {{ frequency(data.item.template_schedule_info.schedule_week_days) }}
          </template>
          <template v-else>
            {{ frequency(data.item.template_schedule_info.type) }}
          </template>
        </template>

        <template #cell(duração)="data">
          {{ duration(data.item.template_schedule_info.duration_type) }}
        </template>

        <template #cell(ação)="data">
          <b-button
            variant="outline-primary"
            @click="send(data)"
          >
            Usar
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalFavorites"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, VBModal, BFormInput, BInputGroup,
  BInputGroupPrepend, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFavoritesList from '../favorite/useFavoritesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchFavorites,
      tableColumns,
      perPage,
      currentPage,
      totalFavorites,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFavoriteListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useFavoritesList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchFavorites,
      tableColumns,
      perPage,
      currentPage,
      totalFavorites,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFavoriteListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },

  data() {
    return {
      selected: [],
      show: false,
    }
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-help')
      }
    },
  },

  mounted() {
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
  },

  methods: {
    time(type, times) {
      let text = ''
      let interval = ''
      if (type === 'FIXED') {
        switch (times.length) {
          case 1:
            interval = '1x ao dia (de 24 em 24 horas)'
            break
          case 2:
            interval = '2x ao dia (de 12 em 12 horas)'
            break
          case 3:
            interval = '3x ao dia (de 8 em 8 horas)'
            break
          case 4:
            interval = '4x ao dia (de 6 em 6 horas)'
            break
          case 6:
            interval = '6x ao dia (de 4 em 4 horas)'
            break
          case 12:
            interval = '12x ao dia (de 2 em 2 horas)'
            break
          case 24:
            interval = '24x ao dia (de 1 em 1 hora)'
            break
          default:
        }
        text += interval
      }

      if (times.length > 0) {
        for (let i = 0; i < times.length; i += 1) {
          if (text !== '') {
            text += '\n'
          }
          text += `${times[i].time} | ${times[i].dose}`
        }
      }
      return text
    },
    frequency(value) {
      let text = ''
      switch (value) {
        case 'RECURRENT':
          text = 'Dias contínuos (sem pausas)'
          break
        case 'ALTERNATED':
          text = 'Dias alternados (4 em 4 dias, 7 em 7…)'
          break
        case 'CUSTOM':
          text = 'Dias específicos da semana'
          break
        default:
          text = ''
      }
      if (text === 'Dias específicos da semana') {
        const arrayWeek = []
        for (let i = 0; i < value.length; i += 1) {
          switch (value[i]) {
            case 'sunday':
              arrayWeek.push('dom')
              break
            case 'monday':
              arrayWeek.push('seg')
              break
            case 'tuesday':
              arrayWeek.push('ter')
              break
            case 'wednesday':
              arrayWeek.push('qua')
              break
            case 'thursday':
              arrayWeek.push('qui')
              break
            case 'friday':
              arrayWeek.push('sex')
              break
            case 'saturday':
              arrayWeek.push('sab')
              break
            default:
          }
        }
        const lengthArray = arrayWeek.length
        let stringWeek = ''
        arrayWeek.forEach((valueArray, index) => {
          stringWeek += valueArray
          if (index < lengthArray - 2) {
            stringWeek += ', '
          } else if (index === lengthArray - 2) {
            stringWeek += ' e '
          }
        })
        return stringWeek
      } if (text === 'Dias alternados (4 em 4 dias, 7 em 7…)') {
        return ''
      }
      return text
    },
    duration(value) {
      let text = ''
      switch (value) {
        case 'CONTINUOUS':
          text = 'Uso contínuo (sem data de término)'
          break
        case 'INTERVAL':
          text = 'Quantidade de dias (início e fim definidos)'
          break
        default:
          text = ''
      }
      return text
    },
    async send(value) {
      const scheduleInfo = value.item.template_schedule_info

      await this.$store.dispatch('formMedication/findEanMedication', value.item.medication_info.ean1)
        .then(response => {
          if (response.data.data.medications_info !== null) {
            this.$store.commit('formMedication/UPDATE_MEDICATION', response.data.data.medications_info[0])
          } else {
            this.$store.commit('formMedication/UPDATE_MEDICATION', '')
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

      if (scheduleInfo.schedule_times_type === 'FREE') {
        this.$store.commit('formMedication/UPDATE_TIME', 'Horários livres')
      } else if (scheduleInfo.schedule_times_type === 'FIXED') {
        let interval = ''
        switch (scheduleInfo.schedule_times.length) {
          case 1:
            interval = { id: 1, name: '1x ao dia (de 24 em 24 horas)', time: 0 }
            break
          case 2:
            interval = { id: 2, name: '2x ao dia (de 12 em 12 horas)', time: 1 }
            break
          case 3:
            interval = { id: 3, name: '3x ao dia (de 8 em 8 horas)', time: 2 }
            break
          case 4:
            interval = { id: 4, name: '4x ao dia (de 6 em 6 horas)', time: 3 }
            break
          case 6:
            interval = { id: 5, name: '6x ao dia (de 4 em 4 horas)', time: 5 }
            break
          case 12:
            interval = { id: 6, name: '12x ao dia (de 2 em 2 horas)', time: 11 }
            break
          case 24:
            interval = { id: 7, name: '24x ao dia (de 1 em 1 hora)', time: 23 }
            break
          default:
        }
        this.$store.commit('formMedication/UPDATE_TIME_INTERVAL', interval)
        this.$store.commit('formMedication/UPDATE_TIME', 'Intervalos fixos (8 em 8h, 6 em 6h…)')
      } else {
        this.$store.commit('formMedication/UPDATE_TIME', 'Intervalos fixos (8 em 8h, 6 em 6h…)')
      }

      if (scheduleInfo.schedule_times.length > 0) {
        let time = []
        let amount = []
        time = scheduleInfo.schedule_times.map(element => element.time)
        amount = scheduleInfo.schedule_times.map(element => element.dose)

        if (scheduleInfo.schedule_times_type === 'FREE') {
          this.$store.commit('formMedication/UPDATE_TIME_TIME_FREE', time)
          this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FREE', amount)
        } else if (scheduleInfo.schedule_times_type === 'FIXED') {
          this.$store.commit('formMedication/UPDATE_TIME_TIME_FIXED', time)
          this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FIXED', amount)
        } else {
          this.$store.commit('formMedication/UPDATE_TIME_TIME_FREE', [])
          this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FREE', [])
          this.$store.commit('formMedication/UPDATE_TIME_TIME_FIXED', [])
          this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FIXED', [])
        }
      }

      if (scheduleInfo.type === 'RECURRENT') {
        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias contínuos (sem pausas)')
      } else if (scheduleInfo.type === 'ALTERNATED') {
        const days = [
          '2',
          '3',
          '5',
          '7',
          '15',
          '+',
        ]

        if (scheduleInfo.days_internal !== null) {
          days.map((element, index) => {
            if (scheduleInfo.days_internal.search(element) !== -1) {
              return 'primary'
            } if (index === 5) {
              return 'primary'
            }
            return 'outline-primary'
          })
        }

        this.$store.commit('formMedication/UPDATE_FREQUENCY_INTERVAL', scheduleInfo.days_internal)

        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias alternados (4 em 4 dias, 7 em 7…)')
      } else if (scheduleInfo.type === 'CUSTOM') {
        let arrayWeekColor = []

        const week = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ]

        arrayWeekColor = week.map(element => {
          if (scheduleInfo.schedule_week_days.toString().search(element) !== -1) {
            return 'primary'
          }
          return 'outline-primary'
        })

        const array = []
        arrayWeekColor.forEach((item, indice) => {
          if (item === 'primary') {
            switch (indice) {
              case 0:
                array.push('dom')
                break
              case 1:
                array.push('seg')
                break
              case 2:
                array.push('ter')
                break
              case 3:
                array.push('qua')
                break
              case 4:
                array.push('qui')
                break
              case 5:
                array.push('sex')
                break
              case 6:
                array.push('sab')
                break
              default:
            }
          }
        })

        const lengthArray = array.length
        let stringWeek = ''

        array.forEach((valueArray, index) => {
          stringWeek += valueArray
          if (index < lengthArray - 2) {
            stringWeek += ', '
          } else if (index === lengthArray - 2) {
            stringWeek += ' e '
          }
        })

        this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK_COLOR', arrayWeekColor)
        this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK_FORMATED', stringWeek)
        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias específicos da semana')
      } else {
        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias contínuos (sem pausas)')
      }

      if (scheduleInfo.duration_type === 'CONTINUOUS') {
        this.$store.commit('formMedication/UPDATE_DURATION', 'Uso contínuo (sem data de término)')
      } else if (scheduleInfo.duration_type === 'INTERVAL') {
        this.$store.commit('formMedication/UPDATE_DURATION', 'Quantidade de dias (início e fim definidos)')
      } else {
        this.$store.commit('formMedication/UPDATE_DURATION', 'Uso contínuo (sem data de término)')
      }

      this.$store.commit('patient/UPDATE_FAVORITE_FORM', true)

      this.$router.push('patient-medication')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.pre-formatted {
  white-space: pre;
}
</style>
