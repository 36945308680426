<template>
  <div>
    <library-user />
    <library-cuco />
  </div>
</template>

<script>
import libraryUser from '../library/Library-user.vue'
import libraryCuco from '../library/Library-cuco.vue'

export default ({
  components: {
    libraryUser,
    libraryCuco,
  },

})
</script>
