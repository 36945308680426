<template>
  <div>
    <div>
      <b-card>
        <b-row>
          <b-col
            cols="6"
          >
            <div>
              Busque por um medicamento:
              <v-select
                v-model="findSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="medication_name"
                :disabled="optionsMedication.length === 0"
                :options="optionsMedication"
                :option:selected="addMedication()"
              />
            </div>
          </b-col>
          <b-col>
            <div>
              <b-form-group>
                Data inicial/final:
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="configDate"
                  @input="dateChange()"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <div>
          <div
            v-for="item in medication"
            :key="item.medication_id"
            class="mt-2"
          >
            <b-button
              variant="outline-primary"
              @click="removeMedication(item)"
            >
              {{ item.medication_name }}
              &nbsp;&nbsp;X
            </b-button>
          </div>
        </div>
      </b-card>
    </div>

    <div v-if="medication.length === 0">
      <b-card>
        <div
          class="text-center mt-2 mb-2"
        >
          Nenhum medicamento encontrado no período solicitado.
        </div>
      </b-card>
    </div>

    <div v-if="medication.length !== 0">
      <b-card>
        <h4>
          Adesão média no período selecionado
        </h4>
        <b-row>
          <b-col
            v-for="item in medication"
            :key="item.medication_id"
            cols="2"
            class="rectangle mt-3 p-1 ml-2"
          >
            {{ item.medication_name }}
            <div
              class="mt-2"
            >
              <h2>
                {{ Math.round(item.adherence) }}%
              </h2>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="showAntera"
          align-h="between"
        >
          <b-col
            sd="10"
          />
          <b-col
            cols
          >
            <div class="mt-4 float-right">
              <b-button
                target="_blank"
                variant="outline-primary"
                href="https://cdn.eurofarma.com.br/wp-content/uploads/2020/07/Bula-do-Profissional-Antara.pdf"
              >
                <feather-icon icon="DownloadIcon" />
                Baixar bula Antara
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div v-if="medication.length !== 0">
      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title class="mb-1">
              Curva de adesão
            </b-card-title>
          </div>
        </b-card-header>

        <b-card-body>
          <line-chart
            v-if="showChart"
            :height="150"
            :data="chartData.data"
            :config="chartjsDataAdherence.lineChart"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton, BCol, BRow, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import 'twix'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import lineChart from '@/layouts/components/charts-components/ChartjsComponentLineChart.vue'
import chartjsDataAdherence from './chartjsDataAdherence'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,
    BCol,
    BRow,
    BFormGroup,

    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    flatPickr,
    lineChart,
  },

  data() {
    return {
      date: `${moment().add(1, 'days').subtract(1, 'month').format('DD/MM/YYYY')} até ${moment().format('DD/MM/YYYY')}`,
      arrayDate: [],
      medication: [],
      optionsMedication: [],
      findSelected: [],
      showAntera: true,
      rangeDate: null,
      showChart: false,
      chartData: null,
      chartjsDataAdherence,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
    }
  },

  watch: {
    medication() {
      this.changeData()
    },
  },

  mounted() {
    this.dateChange()
  },

  methods: {
    listMedication() {
      let start = ''
      let end = ''
      if (this.arrayDate.length === 3) {
        start = moment(this.arrayDate[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        end = moment(this.arrayDate[2], 'DD/MM/YYYY').format('YYYY-MM-DD')
      } else {
        start = moment(this.arrayDate[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        end = moment(this.arrayDate[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
      }

      const dataForm = {
        start_date: start,
        end_date: end,
      }
      this.$store.dispatch('patient/listMedication', dataForm)
        .then(response => {
          this.medication = response.data.data.member_medications_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    addMedication() {
      if (this.findSelected.length !== 0) {
        const indexSelected = this.optionsMedication.indexOf(this.findSelected)
        this.optionsMedication.splice(indexSelected, 1)
        this.medication.push(this.findSelected)
        this.findSelected = ''
      }
      // this.optionsMedication.push(this.findSelected)
    },
    removeMedication(value) {
      this.optionsMedication.push(value)
      const indexSelected = this.medication.indexOf(value)
      this.medication.splice(indexSelected, 1)
      this.changeData()
    },
    dateChange() {
      this.arrayDate = this.date.split(' ')
      if (this.arrayDate.length === 3) {
        this.$store.commit('patient/UPDATE_ADHERENCE_DATE_START', this.arrayDate[0])
        this.$store.commit('patient/UPDATE_ADHERENCE_DATE_END', this.arrayDate[2])
      } else {
        this.$store.commit('patient/UPDATE_ADHERENCE_DATE_START', this.arrayDate[0])
        this.$store.commit('patient/UPDATE_ADHERENCE_DATE_END', this.arrayDate[0])
      }
      this.listMedication()
    },
    changeData() {
      this.showChart = false
      const dataForm = {
        medication: this.medication,
      }
      this.$store.dispatch('patient/dataChartsAdherence', dataForm)
        .then(response => {
          this.loadChart(response)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadChart(data) {
      const start = moment(this.$store.getters['patient/adherenceDateStart'], 'DD/MM/YYYY').format('MM/YYYY')
      const end = moment(this.$store.getters['patient/adherenceDateEnd'], 'DD/MM/YYYY').format('MM/YYYY')
      const diffDay = moment(this.$store.getters['patient/adherenceDateEnd'], 'DD/MM/YYYY').diff(moment(this.$store.getters['patient/adherenceDateStart'], 'DD/MM/YYYY'))
      const days = moment.duration(diffDay).asDays()

      const range = []
      let format = ''

      if (start === end || days <= 30) {
        const itr = moment.twix(moment(this.$store.getters['patient/adherenceDateStart'], 'DD/MM/YYYY'),
          moment(this.$store.getters['patient/adherenceDateEnd'], 'DD/MM/YYYY')).iterate('days')
        while (itr.hasNext()) {
          range.push(itr.next().format('DD/MM/YYYY'))
        }
        format = 'day'
      } else {
        const itr = moment.twix(moment(this.$store.getters['patient/adherenceDateStart'], 'DD/MM/YYYY'),
          moment(this.$store.getters['patient/adherenceDateEnd'], 'DD/MM/YYYY')).iterate('month')
        while (itr.hasNext()) {
          range.push(itr.next().format('MM/YYYY'))
        }
        format = 'month'
      }

      const formData = {
        data: {
          labels: range,
          datasets: [
          ],
        },
      }

      const memberMedicationsInfo = data.data.data.member_medications_info
      let medicationDay = ''
      const adherence = []

      memberMedicationsInfo.forEach(valueMedication => {
        const arrayAdherence = []
        range.forEach((valueRange, indexRange) => {
          let total = 0
          valueMedication.adherence_info.forEach(valueAdherence => {
            if (format === 'day') {
              medicationDay = moment(valueAdherence.day).format('DD/MM/YYYY')
            } else {
              medicationDay = moment(valueAdherence.day).format('MM/YYYY')
            }
            if (valueRange === medicationDay) {
              if (arrayAdherence[indexRange] === undefined) {
                arrayAdherence.splice(indexRange, 0, Math.round(valueAdherence.adherence))
                total += 1
              } else {
                arrayAdherence[indexRange] += Math.round(valueAdherence.adherence)
                total += 1
              }
            }
            if (arrayAdherence[indexRange] === undefined) {
              arrayAdherence.push(null)
            }
          })
          if (format === 'month') {
            if (arrayAdherence[indexRange] !== null) {
              const value = Math.round(arrayAdherence[indexRange] / total)
              arrayAdherence[indexRange] = value
            }
          }
        })
        adherence.push({ medication: valueMedication.medication_name, adherence: arrayAdherence })
      })

      adherence.forEach(value => {
        const color = this.randomColor()

        const newDataset = {
          data: value.adherence,
          label: value.medication,
          borderColor: color,
          backgroundColor: color,
          fill: false,
          // lineTension: 0.5,
          pointStyle: 'circle',
          pointRadius: 4,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: 'white',
          pointHoverBackgroundColor: color,
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: color,
        }
        formData.data.datasets.push(newDataset)
      })

      this.chartData = formData
      this.showChart = true
    },
    randomColor() {
      return `#${Math.floor(Math.random() * (16777215 - Math.random() * 12345678)).toString(16)}`
    },
  },
}
</script>

<style scoped>
  .rectangle {
    text-align: center;
    min-height: 160px;
    box-shadow: 0px 0px 1em rgb(179, 176, 176);
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
