import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function usePhq9List() {
  // Use toast
  const toast = useToast()

  const refPhq9ListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', label: 'Resultados' },
  ]

  const perPage = ref(10)
  const totalPhq9 = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPhq9ListTable.value ? refPhq9ListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPhq9.value,
    }
  })

  const refetchData = () => {
    refPhq9ListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchPhq9 = (ctx, callback) => {
    store
      .dispatch('patient/fetchPhq9', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { ph9_results_info, total } = response.data.data

        callback(ph9_results_info)
        totalPhq9.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePhq9StatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'ACTIVE') return 'success'
    if (status === 'INACTIVE') return 'danger'
    return 'primary'
  }

  return {
    fetchPhq9,
    tableColumns,
    perPage,
    currentPage,
    totalPhq9,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPhq9ListTable,

    resolvePhq9StatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
