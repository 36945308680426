import store from '@/store'

const color = ['#26104D', '#F8C52F']

export function selected(value) {
  store.commit('patient/UPDATE_SELECTED_WEIGHT', value)
}

export const option = {
  option: {
    response: true,
    color,
    legend: {
      icon: 'circle',
      left: '20px',
      padding: [0, 0, 30, 0],
      orient: 'vertical',
    },
    toolbox: {
      feature: {
        magicType: {
          type: ['stack'],
        },
        dataView: {},
      },
    },
    tooltip: {
      confine: 'pointer-events: auto',
      triggerOn: 'mousemove|click',
      position: 'bottom',
      show: true,
      trigger: 'item',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        color: '#5A607F',
      },
      backgroundColor: '#FFFFFF',
      padding: 12,
      extraCssText: 'box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.16); border: 1px solid #EFEFEF;',
      renderMode: 'html',
      formatter(params) {
        selected(params.name)
        const text = `<p class="text-gray-800 mb-0">${params.name}</p>
        <svg height="15" width="15">
          <circle cx="7" cy="7" r="5" fill="${params.color}" />
        </svg>
        <span>${params.value} Kg</span>`
        return text
      },
    },
    xAxis: {
      data: [],
      axisLine: {
        onZero: true,
        lineStyle: {
          color: '#edeeef',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: [
            '#edeeef',
          ],
        },
      },
      splitArea: { show: false },
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        interval: 0,
        rotate: 45,
        color: '#5a607f',
        padding: [10, 0, 0, 0],
      },
    },
    yAxis: {
      scale: 20,
      minInterval: 20,
      axisLine: {
        lineStyle: {
          color: '#edeeef',
        },
      },
      splitLine: {
        lineStyle: {
          color: [
            '#edeeef',
          ],
        },
      },
      name: 'Quilos',
      nameTextStyle: {
        align: 'center',
        fontSize: 15,
        padding: [0, 0, 18, 0],
        color: '#5a607f',
      },
      nameLocation: 'middle',
      axisLabel: {
        color: '#5a607f',
      },
    },
    grid: {
      top: '80px',
      left: '50px',
      right: '30px',
      bottom: '60px',
    },
    series: [
    ],
  },
}
