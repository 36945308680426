<template>
  <div>
    <b-card
      no-body
      class="mb-0 mt-2"
    >
      <patient-tab-reminder-table v-if="false" />
      <patient-tab-reminder-add />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import PatientTabReminderTable from './Patient-tab-reminder-table.vue'
import PatientTabReminderAdd from './Patient-tab-reminder-add.vue'

export default {
  components: {
    BCard,

    PatientTabReminderTable,
    PatientTabReminderAdd,
  },
}
</script>
