<template>
  <div>
    <patient-edit />
    <form-frequency />
  </div>
</template>

<script>
import patientEdit from '../Patient-edit.vue'
import formFrequency from '../../form/other/Form-other-frequency.vue'

export default ({
  components: {
    patientEdit,
    formFrequency,
  },

})
</script>
