<template>
  <div>
    <b-card>
      <div class="mt-2">
        <b-row>
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-start"
          >
            <b-button
              variant="primary"
              @click="send()"
            >
              Enviar conteúdo
            </b-button>
          </b-col>

        </b-row>
      </div>

      <b-row>
        <b-col>
          <h4 class="mt-2">
            Lista de conteúdos enviados
          </h4>
        </b-col>
        <b-col
          v-if="selected.length !== 0"
          cols="4"
          class="mr-2 d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-danger"
            @click="show=true"
          >
            Excluir
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="refContentListTable"
        class="position-relative mt-2"
        :items="fetchContents"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Você ainda não enviou lembretes para seu paciente."
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(title)="data">
          {{ data.item.title }}
        </template>

        <template #cell(date)="data">
          {{ formatDateBR(data.item.released_at) }}
        </template>

        <template #cell(action)="data">
          <b-form-checkbox
            v-model="selected"
            :value="data.item"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          />
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      v-model="show"
      centered
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      ok-title="Excluir"
      hide-header
      @ok="remove"
    >
      <b-card-text class="text-center">
        <feather-icon
          icon="AlertCircleIcon"
          stroke="red"
          size="60"
        />
        <div class="mt-2">
          <strong>Atenção!</strong>
        </div>
        <div class="mt-2">
          Tem certeza de que deseja excluir este(s) conteúdo(s) enviado(s) para seu paciente?
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BFormCheckbox, BCardText,
  BPagination, VBModal, BButton, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useContentsList from './useContestsList'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BCard,
    BFormCheckbox,
    BCardText,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchContents,
      tableColumns,
      perPage,
      currentPage,
      totalContents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContentListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useContentsList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchContents,
      tableColumns,
      perPage,
      currentPage,
      totalContents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContentListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },

  data() {
    return {
      selected: [],
      show: false,
    }
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-help')
      }
    },
  },

  mounted() {
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
  },

  methods: {
    send() {
      this.$store.commit('library/UPDATE_LINK', 'patient')
      this.$store.commit('patient/UPDATE_ACTIVE', 'Enviar conteúdo')
      this.$store.commit('patient/UPDATE_SHOW_CONTENT_TABLE', false)
      this.$store.commit('patient/UPDATE_SHOW_CONTENT_ADD', true)
    },
    remove() {
      this.$store.dispatch('patient/removeContent', { data: this.selected })
        .then(() => {
          this.$refs.refContentListTable.refresh()
          this.selected = []
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .pre-formatted {
    white-space: pre;
  }
  ::v-deep .modal-footer {
    justify-content: center;
  }
</style>
