<template>
  <div>
    <b-card>

      <div class="mt-2">
        <b-row>
          <b-col
            md="4"
          >
            <h4>
              Resultados Questionário PHQ-9
            </h4>
          </b-col>
          <b-col>
            <b-link v-b-modal.modal-no-animation>
              Entender o que é o Questionário PHQ-9
            </b-link>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPhq9ListTable"
        class="position-relative mt-2"
        :items="fetchPhq9"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhuma pesquisa respondida pelo paciente no período."
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(title)="data">
          <strong>Pontuação do Paciente:</strong> {{ data.item.points }}<br>
          Dia do teste: {{ formatDateBR(data.item.result_date) }} - Hora: {{ formatTimeBR(data.item.result_time) }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          />
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPhq9s"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-no-animation"
      content-class="shadow"
      centered
      no-fade
      ok-only
      hide-footer
      size="lg"
    >
      <b-card-text>
        O questionário PHQ-9 foi criado com o objetivo de fazer uma avaliação rápida do estado de humor
        e para verificar se o indivíduo apresenta algum dos principais sinais e sintomas de depressão.
        O resultado pode constituir uma indicação, mas o teste não faz o diagnóstico de depressão.
        Veja abaixo o resultado para cada pontuação:
        <br>
        <br>
        <strong>Resultados de 0 a 4</strong>
        <br>
        - Este resultado sugere que, provavelmente, não tem sintomas depressivos.
        <br>
        <br>
        <strong>Resultados de 5 a 9</strong>
        <br>
        - Este resultado sugere que pode ter sintomas depressivos leves.
        <br>
        <br>
        <strong>Resultados de 10 a 14</strong>
        <br>
        - Este resultado sugere que pode ter sinais de sintomas depressivos moderados.
        <br>
        <br>
        <strong>Resultados de 15 até 20</strong>
        <br>
        - Um resultado de 15 ou mais sugere fortemente que pode ter sintomas depressivos
        de grau moderado a grave.
        <br>
        <br>
        <strong> Resultados acima de 20</strong>
        <br>
        - Os sintomas depressivos são provavelmente graves.
        Fale com o seu médico ou outro profissional de saúde o mais rápido possível.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BPagination, VBModal, BCard, BLink, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import usePhq9List from './usePhq9List'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BLink,
    BCardText,

    vSelect,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchPhq9,
      tableColumns,
      perPage,
      currentPage,
      totalPhq9s,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhq9ListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = usePhq9List()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchPhq9,
      tableColumns,
      perPage,
      currentPage,
      totalPhq9s,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhq9ListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },

  data() {
    return {
    }
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-help')
      }
    },
  },

  mounted() {
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
  },

  methods: {
    formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    },
    formatTimeBR(value) {
      return moment(value, 'hh:mm:ss').format('hh:mm')
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.pre-formatted {
  white-space: pre;
}
</style>
