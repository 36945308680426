<template>
  <div>
    <b-card
      class="p-1"
      no-body
    >
      <b-card-header>
        <div>
          <b-card-title class="text-gray-800 mb-1">
            Pressão arterial
          </b-card-title>
        </div>
      </b-card-header>

      <b-card-body>
        <e-charts
          ref="line"
          :height="250"
          autoresize
          :options="chartjsDataCarePressure.option"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import 'twix'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import theme from '@core/components/charts/echart/theme.json'

import chartjsDataCarePressure from './chartjsDataCarePressure'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ECharts,
  },

  data() {
    return {
      showChart: false,
      chartjsDataCarePressure,

      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
    }
  },

  computed: {
    careDate() {
      return `${this.$store.getters['patient/careDateStart']} até ${this.$store.getters['patient/careDateEnd']}`
    },
  },

  watch: {
    careDate() {
      this.changeData()
    },
  },

  mounted() {
    this.changeData()
  },

  methods: {
    changeData() {
      this.showChart = false

      this.$store.dispatch('patient/dataChartsCareHeartFailure', { monitor_type: 'PRESSURE' })
        .then(response => {
          this.loadChart(response)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadChart(data) {
      const healthMonitorInfo = data.data.data.member_health_monitors_info
      const range = []

      const itr = moment.twix(moment(this.$store.getters['patient/careDateStart'], 'DD/MM/YYYY'),
        moment(this.$store.getters['patient/careDateEnd'], 'DD/MM/YYYY')).iterate('days')
      while (itr.hasNext()) {
        range.push(itr.next().format('DD/MM/YYYY'))
      }

      chartjsDataCarePressure.option.xAxis.data = range

      const arraySystolicData = []
      const arrayDiastolicData = []
      let arrayAdd = true

      range.forEach(valueRange => {
        arrayAdd = true
        healthMonitorInfo.forEach(valueHealthMonitor => {
          const dateHealthMonitorInfo = moment(valueHealthMonitor.occurrence_date).format('DD/MM/YYYY')
          if (valueRange === dateHealthMonitorInfo) {
            arraySystolicData.push(valueHealthMonitor.systolic_pressure)
            arrayDiastolicData.push(valueHealthMonitor.diastolic_pressure)
            arrayAdd = false
          }
        })
        if (arrayAdd) {
          arraySystolicData.push(null)
          arrayDiastolicData.push(null)
        }
      })

      const newModelSeries = [
        {
          name: 'Sistólica (máxima)',
          color: '#26104D',
          data: arraySystolicData,
          smooth: true,
          type: 'line',
          connectNulls: true,
          symbol: 'circle',
          symbolSize: 12,
        },
        {
          name: 'Diastólica (mínima)',
          color: '#EB9EC9',
          data: arrayDiastolicData,
          smooth: true,
          type: 'line',
          connectNulls: true,
          symbol: 'circle',
          symbolSize: 12,
        },
      ]

      chartjsDataCarePressure.option.series = newModelSeries

      this.showChart = true
    },
  },
}
</script>

<style scoped>
  .rectangle {
    text-align: center;
    width: 200px;
    height: 150px;
    box-shadow: 0px 0px 1em rgb(179, 176, 176);
  }
  .echarts {
      width: 100%;
  }
</style>
