const color = ['#26104D', '#0072B2', '#56B4E9', '#009E73', '#F0E442', '#E69F00', '#D55E00', '#EB9EC9', '#AA4499']

export default {
  option: {
    color,
    legend: {
      left: '10%',
      itemGap: 15,
    },
    toolbox: {
      feature: {
        magicType: {
          type: ['stack'],
        },
        dataView: {},
      },
    },
    tooltip: {
      confine: 'pointer-events: auto',
      triggerOn: 'click',
      position(pt) {
        return [pt[0], 200]
      },
      show: true,
      trigger: 'item',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        color: '#4B5259',
      },
      backgroundColor: '#F4F4F4',
      renderMode: 'html',
      formatter(params) {
        let text = ''
        params.data.seizures.forEach(value => {
          if (value.video_path !== null) {
            if (text === '') {
              text += `<strong>${params.seriesName}</strong><br />
                      Dia e horário: ${params.name} ás ${value.occurrence_time}<br />
                      <a style="pointer-events: auto" target="_blank" href="${value.video_path}">Ver vídeo da crise</a>`
            } else {
              text += `<br>
                      Dia e horário: ${params.name} ás ${value.occurrence_time}<br />
                      <a style="pointer-events: auto" target="_blank" href="${value.video_path}">Ver vídeo da crise</a>`
            }
          }
          if (text === '') {
            text += `<strong>${params.seriesName}</strong><br />
                    Dia e horário: ${params.name} ás ${value.occurrence_time}`
          } else {
            text += `<br>
                    Dia e horário: ${params.name} ás ${value.occurrence_time}`
          }
        })
        return text
      },
    },
    xAxis: {
      data: [],
      axisLine: {
        onZero: true,
        lineStyle: {
          color: '#5A607F',
        },
      },
      splitLine: { show: true },
      splitArea: { show: false },
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        interval: 0,
        rotate: 45,
      },
    },
    yAxis: {
      axisLine: {
        lineStyle: {
          color: '#5A607F',
        },
      },
      name: 'Qtde. crises por dia',
      nameTextStyle: {
        align: 'center',
        fontSize: 12,
        padding: 20,
      },
      nameLocation: 'middle',
      minInterval: 1,
    },
    grid: {
      top: '100px',
      left: '50px',
      right: '30px',
      bottom: '80px',
    },
    series: [
    ],
  },
}
