<template>
  <div>
    <b-card>
      <h4 class="mt-2">
        Resumo
      </h4>
      <div class="mt-3">
        <strong>Título</strong>
        <div class="mt-2">
          {{ state.title }}
        </div>
      </div>
      <div
        v-if="state.time !== '' && state.showTime"
        class="mt-3"
      >
        <strong>Horário</strong>
        <div class="mt-2">
          {{ state.time }}
        </div>
      </div>
      <div
        v-if="state.frequency !== '' && state.showFrequency"
        class="mt-3"
      >
        <strong>Frequência</strong>
        <div v-if="state.frequency === 'Todos os dias'">
          <div class="mt-2">
            {{ state.frequency }}
          </div>
        </div>
        <div v-if="state.frequency === 'Dias específicos da semana'">
          <div class="mt-2">
            {{ state.frequency }}: {{ state.frequencyWeekFormated }}
          </div>
        </div>
        <div v-if="state.frequency === 'Um dia específico'">
          <div class="mt-2">
            {{ state.frequency }}: {{ formatDateBR(state.frequencyDate) }}
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },

  data() {
    return {
      state: [],
    }
  },

  mounted() {
    this.state = this.$store.getters['formOther/state']
  },

  methods: {
    formatDateBR(value) {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
  },
}
</script>
