<template>
  <div>
    <div>
      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title class="mb-1">
              Registros de crises
            </b-card-title>
          </div>
        </b-card-header>

        <b-card-body>
          <e-charts
            ref="line"
            :height="150"
            autoresize
            :options="chartjsDataCare.option"
          />
        </b-card-body>
      </b-card>
    </div>
    <div v-if="showChart">
      <patient-tab-care-phq9-table />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import 'twix'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import theme from '@core/components/charts/echart/theme.json'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import PatientTabCarePhq9Table from './Patient-tab-care-phq9-table.vue'
import chartjsDataCare from './chartjsDataCare'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,

    PatientTabCarePhq9Table,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // vSelect,
    ECharts,
  },

  data() {
    return {
      optionCare: [],
      showChart: false,
      chartjsDataCare,

      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
    }
  },

  computed: {
    careDate() {
      return `${this.$store.getters['patient/careDateStart']} até ${this.$store.getters['patient/careDateEnd']}`
    },
  },

  watch: {
    careDate() {
      this.changeData()
    },
  },

  mounted() {
    this.optionsCare()
    this.changeData()
  },

  methods: {
    optionsCare() {
      this.$store.dispatch('patient/listCare')
        .then(response => {
          this.optionCare = response.data.data.member_cares_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeData() {
      this.showChart = false
      this.$store.dispatch('patient/dataChartsCare')
        .then(response => {
          this.loadChart(response)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadChart(data) {
      const seizuresInfo = data.data.data.seizures_info
      const range = []

      const itr = moment.twix(moment(this.$store.getters['patient/careDateStart'], 'DD/MM/YYYY'),
        moment(this.$store.getters['patient/careDateEnd'], 'DD/MM/YYYY')).iterate('days')
      while (itr.hasNext()) {
        range.push(itr.next().format('DD/MM/YYYY'))
      }

      const crisis = ['BILATERAL_TONIC_CLONIC', 'MYOCLONIC', 'ATONIC', 'TONIC', 'EPILEPTIC_SPASMS',
        'ABSENCE', 'AURA', 'FOCAL_MOTOR_WITHOUT_CHANGE', 'FOCAL_WITH_CHANGE']

      chartjsDataCare.option.xAxis.data = range

      const arrayaData = []
      let arraySeizures = []
      let arrayAdd = true
      let total = 0

      crisis.forEach((valueCrisis, indexCresis) => {
        arrayaData.push([])
        range.forEach(valueRange => {
          arrayAdd = true
          total = 0
          arraySeizures = []
          seizuresInfo.forEach(valueseizures => {
            const dateSeizuresInfo = moment(valueseizures.occurrence_date).format('DD/MM/YYYY')
            if (valueRange === dateSeizuresInfo) {
              if (valueCrisis === valueseizures.seizure_type) {
                arraySeizures.push(valueseizures)
                total += 1
                arrayAdd = false
              }
            }
          })
          if (arrayAdd) {
            arrayaData[indexCresis].push(0)
          } else {
            arrayaData[indexCresis].push({ value: total, seizures: arraySeizures })
          }
        })
      })

      chartjsDataCare.option.series = []

      arrayaData.forEach((value, index) => {
        const newModelSeries = {
          color: chartjsDataCare.option.color[index],
          name: this.translateBr(crisis[index]),
          type: 'bar',
          stack: 'advertising',
          data: value,
          barMaxWidth: '25%',
        }
        chartjsDataCare.option.series.push(newModelSeries)
      })

      this.showChart = true
    },
    translateBr(value) {
      switch (value) {
        case 'BILATERAL_TONIC_CLONIC':
          return 'Crise tônico clínica bilateral (ou generalizada)'
        case 'MYOCLONIC':
          return 'Crise mioclônica'
        case 'ATONIC':
          return 'Crise atônica'
        case 'TONIC':
          return 'Crise tônica'
        case 'EPILEPTIC_SPASMS':
          return 'Espasmo epiléptico'
        case 'ABSENCE':
          return 'Crise de ausência'
        case 'AURA':
          return 'Aura'
        case 'FOCAL_MOTOR_WITHOUT_CHANGE':
          return 'Crise focal motora sem alteração da consciência'
        case 'FOCAL_WITH_CHANGE':
          return 'Crise focal com alteração da consciência (crise parcial complexa)'
        default:
          return ''
      }
    },
  },
}
</script>

<style scoped>
  .rectangle {
    text-align: center;
    width: 200px;
    height: 150px;
    box-shadow: 0px 0px 1em rgb(179, 176, 176);
  }
  .echarts {
    width: 100%;
  }
</style>
